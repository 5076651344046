<template>
  <button
    type="button"
    class="cursor-pointer z-10 px-3 focus:bg-blue-gray-100 flex items-center bg-transparent border rounded-md rounded-l-none border-l-0 border-blue-gray-300"
    :class="{ 'border-blue-300 ring-opacity-50 ring-1 ring-blue-400 ring-clip': focused }"
    @click="toggleShow"
  >
    <span
      v-if="!show"
      class="h-5 w-5 text-blue-gray-400"
    >
      <EyeIcon />
    </span>
    <span
      v-if="show"
      class="h-5 w-5 text-blue-gray-400"
    >
      <EyeSlashIcon />
    </span>
  </button>
</template>

<script setup lang="ts">
import { EyeIcon } from '@heroicons/vue/24/solid';
import { EyeSlashIcon } from '@heroicons/vue/24/outline';
import { ref } from 'vue';

defineProps<{ focused?: boolean }>();

const emit = defineEmits(['toggleViewPassword']);

const show = ref(false);

const toggleShow = () => {
  show.value = !show.value;
  emit('toggleViewPassword', show.value);
};
</script>

<style scoped>
.ring-clip {
  clip-path: inset(-5px -5px -5px 0);
}
</style>
