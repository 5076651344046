<template>
  <slot name="leading-icon" />
  <input
    :id="id"
    ref="inputForFocus"
    :value="modelValue"
    :name="name"
    :placeholder="placeholder"
    :type="inputType"
    :class="{
      'text-red-700 border border-red-300 placeholder-red-300 focus:border-red-300 focus:ring-opacity-50 focus:ring-red-400': errors,
      'border-blue-gray-300 placeholder-blue-gray-400 focus:border-blue-300 focus:ring-opacity-50 focus:ring-blue-400 relative': !errors,
      'pr-10': type === 'password' && !errors,
      'pl-10': leadingIcon,
    }"
    class="w-full h-full px-3 py-2 sm:text-sm rounded-md"
    v-bind="$attrs"
    @input="updateModel"
    @focus="inputFocused = true"
    @blur="inputFocused = false"
  >
  <slot name="trailing-icon" />
  <PasswordToggleIcon
    v-if="type === 'password' && !errors"
    class="absolute right-0 h-full"
    :focused="inputFocused"
    @toggle-view-password="toggleViewPassword"
  />
</template>

<script setup lang="ts">
import {
  computed, onMounted, ref, type Ref,
} from 'vue';
import ErrorService from '@/Services/ErrorService';
import PasswordToggleIcon from './PasswordToggleIcon.vue';

const props = withDefaults(
  defineProps<{
    modelValue: any,
    name: string,
    placeholder?: string,
    id?: string,
    type?: string,
    leadingIcon?: boolean,
    trailingIcon?: boolean,
    focusInput?: boolean,
    errorProvider?: string,
  }>(),
  {
    modelValue: '',
    placeholder: '',
    id: '',
    type: 'text',
    errorProvider: 'inertia',
  },
);

const inputForFocus: Ref<HTMLInputElement | null> = ref(null);
const inputType: Ref<string> = ref(props.type);
const inputFocused = ref(false);

const errorService = new ErrorService(props.errorProvider);
const errors = computed(() => errorService.getError(props.name) ?? null);

const toggleViewPassword = (event: string) => {
  inputType.value = event ? 'text' : 'password';
};

const emit = defineEmits(['update:modelValue']);

const updateModel = (e: Event) => emit('update:modelValue', (e.target as HTMLInputElement).value);

onMounted(() => {
  if (props.focusInput && inputForFocus.value?.hasAttribute('autofocus')) {
    inputForFocus.value?.focus();
  }
});
</script>
