import { defineStore } from 'pinia';
import { first } from 'lodash-es';

/* eslint-disable import/prefer-default-export */
export const useCustomErrorsStore = defineStore('CustomErrorsStore', {
  state: () => ({
    errors: {},
  }),

  getters: {
    errorsByKey: (state) => (key) => first(state.errors[key]),
  },

  actions: {
    addError(payload) {
      this.errors[payload.key] = [payload.message];
    },

    clearErrors() {
      this.errors = {};
    },
  },
});
