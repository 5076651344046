import { usePage } from '@inertiajs/vue3';
import { useCustomErrorsStore } from '../Stores/CustomErrors';

export default class ErrorService {
  constructor(errorProvider = 'inertia') {
    this.errorProvider = errorProvider;
  }

  static addError(key, message) {
    useCustomErrorsStore().addError({ key, message });
  }

  static getAllErrors() {
    return useCustomErrorsStore().errors;
  }

  getError(key) {
    if (this.errorProvider === 'inertia') {
      return ErrorService.getInertiaError(key);
    }

    return ErrorService.getCustomError(key);
  }

  static getInertiaError(key) {
    const allErrors = usePage().props.errors;

    return allErrors[key] ?? null;
  }

  static getCustomError(key) {
    return useCustomErrorsStore().errorsByKey(key);
  }

  static clearErrors() {
    useCustomErrorsStore().clearErrors();
  }
}
